<template>
    <div class="container rtl">

        <div class="row pb-4">
            <br>
            <div class="col-12">
                <div class="row">
                    <div class="col-1 text-left">
                        <h4 class="text-left">بەڕێز</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center">{{balance.customer_name}}</h5>
                    </div>
                    <div class="col-1 text-left">
                        <h4 class="text-left">ژ.ت</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center"><a style="direction:rtl!important"
                                    :href="'tel:'+balance.customer_phone">{{balance.customer_phone}}</a></h5>
                    </div>
                    <div class="col-1 text-left">
                        <h4 class="text-left">ژ.</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center">{{balance.balance_out_id}}</h5>
                    </div>
                    
                    
                </div>  
                <div class="row mt-4">
                    <div class="col-1 text-left">
                        <h4 class="text-left">بەروار</h4>
                    </div>
                    <div class="col-3 text-center">
                        <h5 class="text-center">{{balance.balance_out_date}}</h5>
                    </div>
                   
                    
                    
                    
                </div>  
                
            </div>
        </div>
 
   
        <div class="row no-print my-4">
            <div class="col">
                <h4>کردارەکان لەسەر داواکاری</h4>
            </div>
        </div>
        <div class="row no-print">
            <div class="col-6">
                <table class="table">
                    <tbody>
                            
                        <tr>
                            <td colspan="2">سڕینەوەی داواکاری</td>
                            <td>
                                <button @click="openDelete"  type="button" class=" btn-block btn btn-danger">
                                    سڕینەوە
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">وەرگرتنی داواکاری</td>
                            <td>
                                <button @click="updatebalanceStatus"  type="button" class=" btn-block btn btn-success">
                                    وەرگرتن
                                    <i class="fa fa-save" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی    داواکاری</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deletebalance_items">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                id: 0,
                balance:{},
                temp_item: {},
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {


        },
        methods: {
            
            
            
           
            getBalance() {
                axios.post('balance_out/readOne.php', {
                        balance_out_id: this.id
                    })
                    .then(r => {
                        console.log(r);
                        this.balance = r.data.row
                     
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },

            deletebalance_items() {
                let params = {
                    balance_out_id: this.id
                }
                axios.post('balance_out/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: '  باڵانس ڕەتکرایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                                this.$router.push('/money-request')
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            openDelete() {
                $('#deleteModal').modal('show')
            },
      
            updatebalanceStatus() {
                axios.post('balance_out/changeStatus.php', {
                        balance_out_status: 'done',
                        balance_out_id: this.id,
                    })
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            this.msg = {
                                model: true,
                                text: 'باڵانس نێردرا',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                            this.$router.push('/money-request')
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
        
        },
        mounted() {
            this.id = this.$route.params.id;
            $('#deleteModal').modal('hide')
            this.getBalance()
        },
    }
</script>
<style>
    .my-table {
        width: 100%;
        border: 1px solid black;
    }

    .my-table th {
        padding: 10px;
        background-color: #E8EAF6;
        color: black;
    }

    .my-table td {
        padding: 5px;
    }

    .wasl-table {
        width: 100%;
        border: 1px solid black;
    }

    .wasl-table th {
        padding: 10px;
        background-color: #E8EAF6;
    }
</style>